import React from "react";
import { graphql, useStaticQuery } from "gatsby";

export const useCalculatorData = () => {
  const query = useStaticQuery(graphql`
    query {
      img1: file(relativePath: { eq: "image/calculator-hero.png" }) {
        childImageSharp {
          fluid(maxHeight: 600, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
    }
  `);

  const types = {
    textbox: "textbox",
    form: "form",
    table: "table",
  };

  return {
    metaInfo: {
      title: "Kalkulator",
      ogDescription:
        "Dla każdego frezera lub tokarza bardzo istotną sprawą jest dobór odpowiednich parametrów skrawania. Ich niewłaściwy wybór wpływa na znacznie szybsze zużycie narzędzi i jest nieekonomiczny. Jak zatem dobierać odpowiednie parametry skrawania? Parametry, których najczęściej poszukujemy to n - obroty - i F - posuw.",
    },
    hero: {
      title: "Kalkulator parametrów skrawania",
      subtitle:
        "Dla każdego frezera lub tokarza bardzo istotną sprawą jest dobór odpowiednich parametrów skrawania. Ich niewłaściwy wybór wpływa na znacznie szybsze zużycie narzędzi i jest nieekonomiczny. Jak zatem dobierać odpowiednie parametry skrawania? Parametry, których najczęściej poszukujemy to n - obroty - i F - posuw.",
      bg: query.img1,
    },
    sections: {
      rpmCalc: {
        left: {
          type: types.textbox,
          data: {
            title: (
              <>
                Kalkulator obrotów
                <br />
                narzędzia <i>n</i>
              </>
            ),
            subtitle:
              "Kalkulator parametru skrawania n pozwoli Ci łatwo obliczyć prędkość obrotową wrzeciona, czyli ilość obrotów na minutę. Podaj średnicę narzędzia i prędkość skrawania.",
          },
        },
        right: {
          type: types.form,
          data: {
            d: {
              label: "D = Średnica Narzędzia [mm]",
              value: "10",
              id: "d",
            },
            vc: {
              label: "Vc = Prędkość skrawania [m/min]",
              value: "100",
              id: "vc",
            },
            n: {
              label: "n = Obroty [obr/min]",
              id: "rpm",
            },
          },
        },

        bgText: "kalkulator",
      },
      rpmCalcExplained: {
        left: {
          background: "light_gray",
          type: types.textbox,
          data: {
            title: <>Obroty narzędzia n</>,
            subtitle:
              "Do obliczenia obrotów narzędzia n potrzebna jest znajomość prędkości skrawania Vc, czyli obracania się ostrza skrawającego, z zasady podawana w metrach na minutę.",
            math: {
              result: "Vc = ",
              upper: <>d * &pi; * n </>,
              lower: "1000",
            },
            text: (
              <>
                gdzie
                <br />d - średnica narzędzia lub detalu podawana w mm.
              </>
            ),
          },
        },
        right: {
          type: types.textbox,
          data: {
            subtitle: "Po przekształceniu otrzymujemy wzór na obroty n:",
            math: {
              result: "n = ",
              upper: "Vc * 1000",
              lower: <>&pi; * d </>,
            },
            text:
              "Zbyt niska prędkość skrawania prowadzi do powstawania narostu i stępienia krawędzi, podczas gdy zbyt wysoka wpływa na szybsze starcie płytki, powstawanie odkształceń i słabe wykończenie.",
          },
        },
      },
      feedCalc: {
        left: {
          type: types.textbox,
          data: {
            title: (
              <>
                Posuw narzędzia <i>F</i>
              </>
            ),
            subtitle:
              "Kalkulator posuwu narzędzia F umożliwia obliczenie posuwu dla jednego ostrza. Jest zależny od ilości ostrzy posiadanego narzędzia (Z) i jego obrotów (n).",
          },
        },
        right: {
          type: types.form,
          data: {
            n: {
              label: "n = Obroty narzędzia [obr/min]",
              value: "1592",
              id: "n",
            },
            z: {
              label: "Z = Ilość ostrzy",
              value: "2",
              id: "z",
            },
            fz: {
              label: "fz = Posuw na ząb [mm/ostrze]",
              value: "0.2",
              id: "fz",
            },
            f: {
              label: "f = Posuw [mm/min]",
              id: "f",
            },
          },
        },
      },
      feedCalcExplained: {
        background: "dark_gray",
        left: {
          type: types.textbox,
          data: {
            subtitle:
              "Znając prędkość obrotową n, można obliczyć dopasowany do niej posuw F.",
            math: {
              result: "F = ",
              upper: "Fz * n * Z",
            },
            text: (
              <>
                gdzie: <br />
                f - posuw narzędzia, <br />
                fz – posuw na jedno ostrze, <br />
                Z – ilość ostrzy narzędzia, <br />
                n – obroty narzędzia lub detalu obrabianego. <br />
              </>
            ),
          },
        },

        right: {
          type: types.table,
          data: {
            heading: ["", "Stal HSS", "Węglik spiekany VHM"],
            rows: [
              ["[m/min]", "10 ÷ 20", "100 ÷ 120"],
              ["fz [mm/ostrze]", "0,2 ÷ 0,4", "0,02 ÷ 0,04"],
            ],
          },
        },
      },
    },

    params: {},
    stats: {},
  };
};

export default useCalculatorData;
